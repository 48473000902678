import Authentication from '@/commons/Authentication';
import DataComponentBase from '@/commons/DataComponentBase';
import {ReleaseVersionHandler} from "@/commons/ReleaseVersionHandler";


export default {
    name: 'Login',
    extends: DataComponentBase,

    data() {
        return {
            window: 0,

            email: "",
            password: "",
            passwordNew: "",
            passwordNewAgain: "",
            showPassword: false,
            releaseVersionHandler: new ReleaseVersionHandler(this.$log, this.$store),
        }
    },

    created() {
        this.selectedEntity = this.createEntityObj(null);
        this.lastloggeduserUsername = (localStorage.getItem("lastloggeduser_username"))? localStorage.getItem("lastloggeduser_username"):this.email;
        if (!this.$utils.isPresent(this.$store.getters.appBackendVersion) || !this.$utils.isPresent(this.$store.getters.appBackendLastBuildTime) ) {
            this.releaseVersionHandler.fetchBackendLastBuildVersion().then(response => {})
            .catch(error => {
                this.$log.error(error);
                this.$store.commit('setStatusCode', error.getCode());
                this.$store.commit('setSnackbarEnabled', true);
            })
            .finally(() => {});
        }
    
        this.selectedEntity = this.createEntityObj({ email: this.lastloggeduserUsername, password: null });
      },
    
    computed: {
    },

    methods: {
        createEntityObj(entity = null) {
            var md5 = require("md5");
            let returnObj = {};
            returnObj.email = entity === null || entity.email === null ? '' : entity.email;
            returnObj.password = entity === null || entity.password === null ? '12345678' : md5(entity.password);
            return returnObj;
        },
        login() {
            if (this.$refs.loginForm.validate()) {
                this.loginBtnDisable = true;
                //let selectedEntity = { email: this.email, password: md5(this.password) };
                let entity = this.createEntityObj(this.selectedEntity);
                Authentication.login(entity)
                    .then((respLogin) => {
                        this.$log.debug("-----respLogin");
                        const access_token = sessionStorage.access_token;
                        localStorage.setItem("lastloggeduser_username", entity.email);
                        this.$log.debug(respLogin);
                        // if (respLogin.success) {
                        if (access_token) {
                            Authentication.getMyDetails()
                                .then(respMyDetails => {
                                    this.$log.debug('---- Login - getMyDetails ----1');
                                    Authentication.getUserRights()
                                        .then(respUserRights => {
                                            this.$log.debug('---- Login - getUserRights ----1');
                                            if (respUserRights.length > 0) {
                                                this.$router.push("/home");
                                                this.$store.commit('setStatusCode', "299");
                                            }
                                            else {
                                                this.$store.commit('setStatusCode', "norights");
                                            }
                                            this.$store.commit('setSnackbarEnabled', true);
                                        })
                                        .finally(() => { })
                                })
                        } else {
                            this.$store.commit('setStatusCode', "loginFailed");
                            this.$store.commit('setSnackbarEnabled', true);
                        }
                    })
                    .catch(() => {
                        this.$log.debug("========= login error cacth ==========");
                        this.$store.commit('setStatusCode', "loginFailed");
                        this.$store.commit('setSnackbarEnabled', true);
                    })
                    .finally(() => {
                        this.$log.debug("========= login finally ==========");
                        this.loginBtnDisable = false;
                    });
            }
        },

        switchToLogin() {
            this.window = 0;
        },
        switchToPasswordRecovery() {
            this.window = 1;
        },
        sendPasswordRecoveryEmail() {
            //..
            this.window = 2;
        },
        changePassword() {
            //..
            this.window = 0;
        }
    },

    watch: {
    }
}
